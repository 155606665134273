import {Form, Row} from 'react-bootstrap';
import {Ticket} from '../../../../../model/Ticket';
import {renderFormProps} from '../../../../FormController';
import TableListing, {ListingProps,} from '../../../../../components/TableListing';
import {ControlSwitchInput} from "../../../../../components/SwitchButton";
import {useEffect, useState} from "react";
import useDatasource from "../../../../../hooks/useDatasource";
import axios from "axios";
import {InvoicePdfNormalizer} from "../../../../../services/normalizer/InvoicePdfNormalizer";
import {InvoicePdf} from "../../../../../model/InvoicePdf";

interface BillingProps extends renderFormProps<Ticket> {
    resource: Ticket,
}

const columns: ListingProps<InvoicePdf>['columns'] = [
    {label: 'Nom', property: 'name'},
    {label: 'Date de facture', property: 'invoiceDate'},
    {label: 'Fichier', property: 'fileName'},
];

const ListingTableStyles = {
    theadClasses: 'small bg-light',
    noStripe: true,

};

export const Billing = ({register, resource, control}: BillingProps) => {
    const shipment = resource.shipment;

    const [invoicePdfs, setInvoicePdfs] = useState<InvoicePdf[]>([]);
    const {index} = useDatasource<InvoicePdf>('invoice_pdfs', InvoicePdfNormalizer);

    useEffect(() => {
        if (!resource.shipment.derudderReference) {
            return
        }
        const controller = new AbortController();

        index(
            {caseNumber: resource.shipment.derudderReference}, undefined, controller
        ).then(
            setInvoicePdfs
        ).catch(err => {
            if (axios.isCancel(err)) {
                return;
            }
            throw err;
        });

        return () => {
            controller.abort();
        }
    }, [index, resource]);

    return (
        <Row className={'ms-0 me-0 pt-3 pb-5'}>
            <h2>Facturation</h2>
            <div className='mb-3'>
                <label htmlFor={'billingComment'}>Commentaire</label>
                <Form.Control
                    as="textarea"
                    rows={4}
                    {...register('billingComment')}
                />
            </div>
            <Row>
                <div className="col-6">
                    <TableListing columns={columns}
                                  resources={invoicePdfs}
                                  isLoading={false}
                                  tableStyles={ListingTableStyles}/>

                </div>
            </Row>
            <div>
                <ControlSwitchInput
                    label={'Dossier archivé ?'}
                    control={control}
                    name={'shipment.archived'}
                    value={shipment.archived}
                />
            </div>
        </Row>
    );
};