import React from 'react';
import Dropzone, {DropzoneProps} from 'react-dropzone';
import {FileType} from "./FilePreview";

interface FileUploadZoneProps extends DropzoneProps {
    onFileAdd?: (files: FileType[]) => void;
}

const FileUploadZone = ({onFileAdd, ...props}: FileUploadZoneProps) => {
    /**
     * DropZone wrapper to add preview data and compute File size
     */
    const handleAcceptedFiles = (files: File[]) => {

        const filesType = files.map((file) =>
            Object.assign(file, {
                preview: file['type'].split('/')[0] === 'image' ? URL.createObjectURL(file) : undefined,
                formattedSize: formatBytes(file.size),
            })
        );

        if (onFileAdd) {
            onFileAdd(filesType);
        }
    };

    /**
     * Formats the size
     */
    const formatBytes = (bytes: number, decimals: number = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    return (
        <Dropzone {...props} onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
                <div className="dropzone" {...getRootProps()}>
                    <div className="text-center needsclick">
                        <input {...getInputProps()} />
                        <i className="h3 text-muted dripicons-cloud-upload"></i>
                        <h4>Déposez vos fichier ici ou cliquez pour téléverser.</h4>
                    </div>
                </div>
            )}
        </Dropzone>
    );
};

export default FileUploadZone;
